/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/home",
    name: "الرئيسية",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    header: "المتجر",
    i18n: "Store",
    items:[
      {
        url: "/products",
        name: "المنتجات",
        slug: "Products",
        icon: "GridIcon",
      },
      {
        url: "/brands",
        name: "العلامات التجارية",
        slug: "Brands",
        icon: "GridIcon",
      },
      {
        url: "/categories",
        name: "التصنيفات",
        slug: "categories",
        icon: "GridIcon",
      },
      {
        url: "/orders",
        name: "الطلبات",
        slug: "Orders",
        icon: "ShoppingCartIcon",
      },
      {
        url: "/transactions",
        name: "التحويلات المالية",
        slug: "transactions",
        icon: "DollarSignIcon",
      },
      {
        url: '/requests',
        name: "طلبات الصيانة",
        slug: "requests",
        icon: "PackageIcon",
      },
      {
        url: "/packages",
        name: "عروض المنتجات",
        slug: "Packages",
        icon: "PackageIcon",
      },
      {
        url: "/contests",
        name: "المسابقات",
        slug: "Contests",
        icon: "GiftIcon",
      },
      {
        url: "/banners",
        name: "لوحة الاعلانات",
        slug: "banners",
        icon: "MonitorIcon",
      },
      {
        url: "/offer-products",
        name: "عروض التخفيضات",
        slug: "offerProducts",
        icon: "MonitorIcon",
      }
    ],
  },
  {
    header: "الأدوات",
    i18n: "Tools",
    items:[
      {
        url: '/coupons',
        name: "كوبونات التخفيض",
        slug: "Coupons",
        icon: "PercentIcon",
      },
      {
        url: '/fastUpdate',
        name: "تعديل الأسعار",
        slug: "Fast Update",
        icon: "EditIcon",
      },
      {
        url: '/contests-results',
        name: "نتائج المسابقات",
        slug: "Contest Results",
        icon: "BarChart2Icon",
      },
      {
        url: '/archive',
        name: "أرشيف المنتجات",
        slug: "archive",
        icon: "ArchiveIcon",
      },

      {
        url: '/archived-orders',
        name: "أرشيف الطلبات",
        slug: "archived-orders",
        icon: "ArchiveIcon",
      },
      {
        url: '/chat',
        name: "غرف المحادثة",
        slug: "Chat Rooms",
        icon: "MessageCircleIcon",
      }
    ],
  },
  {
    header: "المستخدمون",
    i18n: "Users",
    items:[
      {
        url: "/users",
        name: "العملاء",
        slug: "Users",
        icon: "UsersIcon",
      },
      {
        url: "/admins",
        name: "المشرفين",
        slug: "Users",
        icon: "UserIcon",
      },
      {
        url: "/delivery",
        name: "المندوبين",
        slug: "delivery",
        icon: "UserIcon",
      },
    ],
  },
]
