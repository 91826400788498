import axios from "@/axios.js"

export default {
    fetchNotifications({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/notifications/index`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getNotification({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/notifications/index/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    readNotification({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/notifications/change-read-status/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    

}