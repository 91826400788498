/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/home",
    name: "الرئيسية",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    header: "المتجر",
    i18n: "Store",
    items:[
      {
        url: "/orders",
        name: "الطلبات",
        slug: "Orders",
        icon: "ShoppingCartIcon",
      },
    ],
  },
  {
    header: "الأدوات",
    i18n: "Tools",
    items:[
      {
        url: '/fastUpdate',
        name: "تعديل الأسعار",
        slug: "Fast Update",
        icon: "EditIcon",
      },
    ],
  },
]
